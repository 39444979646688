.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.pagination li {
  margin: 0 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination li a,
.pagination li span {
  display: block;
  padding: 5px 10px;
  border-radius: 4px;
  transition: background-color 0.3s;
  color: #333;
  font-weight: 500;
}

.pagination li.active a,
.pagination li.active span {
  background-color: #6c757d;
  color: #fff;
}

.pagination li.disabled a,
.pagination li.disabled span {
  opacity: 0.5;
  cursor: not-allowed;
}

/* .field{
    margin-left: 20px;
    margin-right: 20px;
  } */
